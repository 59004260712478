<template>
    <component :is="loaded === false ? 'div' : 'div'">
        <b-modal id="modal-center" centered v-model="isShowModal" hide-footer @close="onClose" @hidden="onClose" :no-close-on-backdrop="notCloseBackDrop">
            <template #modal-title>
                <slot name="title"></slot>
            </template>
            <slot name="icon">
            </slot>
            <slot name="content">
            </slot>
            <slot name="footer">
            </slot>
            
        </b-modal>
    </component>
</template>
<script>
export default {
    props:{
        showModal: {
            type: Boolean,
            default: false,
        },
        notCloseBackDrop: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loaded: false,
        }
    },
    computed:{
        isShowModal:{
            get(){
                return this.showModal;
            },
            set(){
                return !this.showModal;
            }
        },
    },
    methods:{
        onClose(){
            this.$emit("closeModal",false);
        }
    }
    
}
</script>